<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 域名對接總表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true"
                     :model="formInline"
                     class="demo-form-inline">
                <el-form-item label="客戶ID:">
                    <el-input v-model="formInline.merchantId"
                              placeholder="請以,區隔(如:18,24,11)"></el-input>
                </el-form-item>
                <el-form-item label="網域來源:"
                              prop="source_id">
                    <el-select v-model="formInline.source_id"
                               multiple
                               collapse-tags
                               placeholder="請選擇"
                               @change="changeSelect">
                        <el-option v-for="item in sourceList"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日期:">
                    <el-date-picker v-model="formInline.datetime"
                                    type="daterange"
                                    :shortcuts="shortcuts"
                                    :picker-options="pickerOptions"
                                    :disabledDate="disabledDateFun"
                                    range-separator="至"
                                    start-placeholder="開始日期"
                                    end-placeholder="結束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="handleSearch">查詢</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="clearSearch">清除</el-button>
                </el-form-item>
            </el-form>
            <div class="pd-top-bottom">
                <el-button size="medium"
                           type="primary"
                           @click="exportSummary">匯出總表</el-button>
            </div>
            <div class="search-msg">
                <el-row>
                    <h3>查詢結果</h3>
                </el-row>
                <el-row>ID:{{merchantList.id}}</el-row>
                <el-row>名稱:{{merchantList.name}}</el-row>
            </div>
            <table class="descriptions-table"
                   v-loading="listLoading">
                <tbody align="center">
                    <tr>
                        <th colspan="4">網域平台總對接數(當前同步數)</th>
                    </tr>
                    <tr>
                        <td>{{searchSourceList.slice(0, -1)}}</td>
                        <td colspan="3">{{summaryList.total}}</td>
                    </tr>
                    <tr>
                        <th>(周)日期</th>
                        <th>(周)平均同步數</th>
                        <th>(周)落差</th>
                        <th>周續約數</th>
                    </tr>
                    <tr v-for="(item,index) in summaryList.week"
                        :key="index">
                        <td>{{item.label_text}}</td>
                        <td>{{item.import}}</td>
                        <td>{{item.import_total}}</td>
                        <td>{{item.renew_total}}</td>
                    </tr>
                    <tr v-if="summaryList.week && summaryList.week.length <1">
                        <td colspan="4">暫無資料</td>
                    </tr>
                    <tr>
                        <th>月份</th>
                        <th>(月)平均同步數</th>
                        <th>(月)落差</th>
                        <th>月續約數</th>
                    </tr>
                    <tr v-for="(item,index) in summaryList.month"
                        :key="index">
                        <td>{{item.label_text}}</td>
                        <td>{{item.import}}</td>
                        <td>{{item.import_total}}</td>
                        <td>{{item.renew_total}}</td>
                    </tr>
                    <tr v-if="summaryList.month && summaryList.month.length <1">
                        <td colspan="4">暫無資料</td>
                    </tr>
                    <tr>
                        <th>年份</th>
                        <th>(年)平均同步數</th>
                        <th>(年)落差</th>
                        <th>年續約數</th>
                    </tr>
                    <tr v-for="(item,index) in summaryList.year"
                        :key="index">
                        <td>{{item.label_text}}</td>
                        <td>{{item.import}}</td>
                        <td>{{item.import_total}}</td>
                        <td>{{item.renew_total}}</td>
                    </tr>
                    <tr v-if="summaryList.year && summaryList.year.length <1">
                        <td colspan="4">暫無資料</td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>

<script>
import { reportSummary, domainSourceList, reportSummaryExport } from '../../api/index'
import { dateFormat } from '@/utils/dateFormat.js'
export default {
    data() {
        return {
            formInline: { datetime: [] },
            summaryList: {},
            sourceList: {},
            searchSourceList: '',
            merchantList: {},
            startDay: '',
            endDay: '',
            listLoading: false,
            dateMax: '',
            dateMin: '',
            shortcuts: [
                {
                    text: '1日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1)
                        return [start, end]
                    })(),
                },
                {
                    text: '7日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
                        return [start, end]
                    })(),
                },
                {
                    text: '30日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 30)
                        return [start, end]
                    })(),
                },
            ],
        }
    },
    computed: {
        pickerOptions() {
            return this.disabledDateFun()
        },
    },
    created() {
        this.getDateScope()
    },
    async mounted() {
        await this.getDomainSourceList()
        this.getReportSummary()
    },
    methods: {
        disabledDateFun(time) {
            if (time) {
                return new Date(this.dateMin).getTime() > time.getTime() || time.getTime() > new Date(this.dateMax).getTime()
            }
        },
        getReportSummary() {
            if (this.formInline.datetime.length > 0) {
                this.startDay = dateFormat(this.formInline.datetime[0])
                this.endDay = dateFormat(this.formInline.datetime[1])
            } else {
                this.getDateScope()
            }
            let data = {
                merchant_id: this.formInline.merchantId ? this.formInline.merchantId : null,
                domain_source_id: this.formInline.source_id.length > 0 ? JSON.stringify(this.formInline.source_id) : null,
                date_at: this.startDay ? this.startDay : null,
                date_end: this.endDay ? this.endDay : null,
            }
            this.listLoading = true
            reportSummary(data).then((res) => {
                if (res.code == 300) {
                    this.summaryList = res.data.data_info
                    this.merchantList = res.data.data_info.merchant
                    this.dateMax = res.data.data_info.date_limit.date_max
                    this.dateMin = res.data.data_info.date_limit.date_min
                } else if (res.code == 500) {
                    this.$message.error(res.message)
                }
                this.listLoading = false
            })
        },
        async getDomainSourceList() {
            await new Promise((resolve) => {
                domainSourceList().then((res) => {
                    this.sourceList = res.data.data_list
                    this.sourceList.forEach((element) => {
                        this.formInline.source_id.push(element.value)
                        this.searchSourceList += element.name + ','
                    })
                    resolve()
                })
            })
        },
        handleSearch() {
            if (this.formInline.source_id.length > 0) {
                this.searchSourceList = ''
                this.sourceList.forEach((item) => {
                    return this.formInline.source_id.forEach((element) => {
                        if (item.value == element) {
                            this.searchSourceList += item.name + ','
                        }
                    })
                })
            } else {
                this.searchSourceList = ''
                this.sourceList.forEach((element) => {
                    this.searchSourceList += element.name + ','
                })
            }
            this.getReportSummary()
        },
        clearSearch() {
            this.formInline = { source_id: '', datetime: [] }
        },
        exportSummary() {
            if (this.formInline.datetime) {
                this.startDay = dateFormat(this.formInline.datetime[0])
                this.endDay = dateFormat(this.formInline.datetime[1])
            } else {
                this.startDay = ''
                this.endDay = ''
            }
            let data = {
                merchant_id: this.formInline.merchantId ? this.formInline.merchantId : null,
                domain_source_id: this.formInline.source_id.length > 0 ? JSON.stringify(this.formInline.source_id) : null,
                date_at: this.startDay ? this.startDay : null,
                date_end: this.endDay ? this.endDay : null,
            }
            reportSummaryExport(data).then((res) => {
                this.excelTake(res, '匯出域名對接總表')
            })
        },
        //接收excel
        excelTake(res, title) {
            const xlsx = 'application/vnd.ms-excel'
            const blob = new Blob([res], { type: xlsx })
            const fileReader = new FileReader()
            let _this = this
            fileReader.readAsText(blob, 'utf-8')
            fileReader.onload = function () {
                if (res.type === 'application/json') {
                    let msg = JSON.parse(fileReader.result).message
                    if (JSON.parse(fileReader.result).code == 500) {
                        _this.$message.error(msg)
                    }
                } else {
                    const a = document.createElement('a') // 轉換完成，建立一個a標籤用於下載
                    a.download = `${title}.xlsx`
                    a.href = window.URL.createObjectURL(blob)
                    a.click()
                    a.remove()
                }
            }
        },
        changeSelect(val) {
            console.log(val)
        },
        //預設日期範圍
        getDateScope() {
            let date = new Date(),
                y = date.getFullYear(),
                m = date.getMonth()
            let firstDay = new Date(y, m, 1)
            this.formInline.datetime.push(firstDay)
            this.formInline.datetime.push(date)
        },
    },
}
</script>

<style lang="scss" scoped>
.descriptions-table {
    width: 100%;
    border-collapse: collapse;
    th {
        border: 1px solid #ebeef5;
        background: #f5f7fa;
        padding: 8px 11px;
    }
    td {
        border: 1px solid #ebeef5;
        padding: 8px 11px;
        max-width: 200px;
        word-break: break-all;
    }
}
.pd-top-bottom {
    padding: 20px 0px;
}
.search-msg {
    padding-bottom: 10px;
    .el-row {
        padding: 5px 0px;
    }
}
</style>