//时间格式化
export function dateFormat(dt) {
    var year = dt.getFullYear() //年
    var month = dt.getMonth() + 1 //月
    var date = dt.getDate() //日
    month = month < 10 ? '0' + month : month
    date = date < 10 ? '0' + date : date
    var str = year + '-' + month + '-' + date
    return str
}
//时间格式化-月
export function dateFormatMonth(dt) {
    var year = dt.getFullYear() //年
    var month = dt.getMonth() + 1 //月
    month = month < 10 ? '0' + month : month
    var str = year + '-' + month
    return str
}
